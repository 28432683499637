import cubejs from "@cubejs-client/core";
import jwt from "jsonwebtoken";
import { CUBE_API_SECRET, host } from "../../constant";
import { AuthService } from "../AuthService";

/**
 * @description Função que monta as configurações inicias do CubeJS e retorna uma instância única pra uso no app.
 */
export function generateCubeInstance() {
	const API_URL = host;

	const { appId, restrictions, userId } = AuthService.getInstance().getAuthData();

	const cubeJsToken = jwt.sign({ appId, restricao: restrictions, userId }, CUBE_API_SECRET, {
		expiresIn: "30d",
	});

	return cubejs(cubeJsToken, {
		apiUrl: `${API_URL}/cubejs-api/v1`,
	});
}
