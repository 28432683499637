import gql from "graphql-tag";

export const CREATE_DASHBOARD_ITEM = gql`
	mutation CreateDashboardItem($input: DashboardItemInput) {
		createDashboardItem(input: $input) {
			id
			layout
			vizState
			name
		}
	}
`;

export const UPDATE_DASHBOARD_ITEM = gql`
	mutation UpdateDashboardItem($id: String!, $input: DashboardItemInput) {
		updateDashboardItem(id: $id, input: $input) {
			id
			layout
			vizState
			name
		}
	}
`;

/**
 * Action para mapear uma mutação de atualização em massa de vários itens de dashboard.
 */
export const UPDATE_DASHBOARD_ITEMS = gql`
	mutation UpdateDashboardItems($items: [UpdateDashboardItemsProps]!) {
		updateDashboardItems(items: $items) {
			id
			layout
			vizState
			name
		}
	}
`;

export const DELETE_DASHBOARD_ITEM = gql`
	mutation DeleteDashboardItem($id: String!) {
		deleteDashboardItem(id: $id) {
			id
			layout
			vizState
			name
		}
	}
`;
