export const translate = {
	contains: {
		name: "contém",
		title: "contém",
	},
	notContains: {
		name: "não contém",
		title: "não contém",
	},
	equals: {
		name: "é igual a",
		title: "é igual a",
	},
	notEquals: {
		name: "é diferente de",
		title: "é diferente de",
	},
	set: {
		name: "incluí",
		title: "incluí",
	},
	notSet: {
		name: "não Incluí",
		title: "não Incluí",
	},
	gt: {
		name: "Maior que",
		title: "Maior que",
	},
	gte: {
		name: "maior ou igual",
		title: "maior ou igual",
	},
	lt: {
		name: "menor que",
		title: "menor que",
	},
	lte: {
		name: "menor ou igual",
		title: "menor ou igual",
	},
};
