import { useMutation } from "@apollo/react-hooks";
import { Card, DatePicker, Menu } from "antd";
import update from "immutability-helper";
import { get, isArray } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { omitProp } from "toolkit-extra";
import { UPDATE_DASHBOARD_ITEMS } from "../../graphql/mutations";
import { GET_DASHBOARD_ITEMS } from "../../graphql/queries";
import ButtonDropdown from "../QueryBuilder/ButtonDropdown";

const Container = styled(Card)`
	margin: 1rem 28px;
	margin-bottom: 0;
	border-radius: 4px;

	& > div {
		padding: 1rem 1.5rem;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
	text-align: center;

	@media (min-width: 640px) {
		flex-direction: row;
		gap: 1rem;
	}
`;

const FormFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
	text-align: center;
	width: 100%;

	@media (min-width: 640px) {
		flex-direction: row;
		gap: 1rem;
		width: fit-content;
	}
`;

const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;

	border-bottom: 1px solid #dbdbdb;
	padding-bottom: 1rem;

	&:last-child {
		border: none;
		padding: 0;
	}

	@media (min-width: 640px) {
		width: fit-content;
		flex-direction: row;
		gap: 1rem;

		border: none;
		padding: 0;

		border-right: 1px solid #dbdbdb;
		padding-right: 1rem;
	}
`;

const Title = styled.h3`
	margin: 0;
`;

const InputLabel = styled.p`
	margin: 0;
`;

const RangeSelector = styled(ButtonDropdown)`
	border: 1px solid #024893;
	padding: 0 8px;
	width: 100%;
	min-width: 96px;

	@media (min-width: 640px) {
		width: fit-content;
	}
`;

const RangeCalendarSelector = styled(DatePicker.RangePicker)`
	margin-left: 0 !important;
	width: 100%;
`;

/**
 * Ranges possíveis para a data.
 *
 * @type {{title: string, value?: string}[]}
 */
const localDateRanges = [
	{ title: "Várias datas", value: "VARIA" },
	{ title: "Últimos 30 dias", value: "Last 30 days" },
	{ title: "Customizado", value: "Custom" },
	{ title: "Todos", value: undefined },
	{ title: "Hoje", value: "Today" },
	{ title: "Ontem", value: "Yesterday" },
	{ title: "Esta semana", value: "This week" },
	{ title: "Este mês", value: "This month" },
	{ title: "Este trimestre", value: "This quarter" },
	{ title: "Este ano", value: "This year" },
	{ title: "Últimos 7 dias", value: "Last 7 days" },
	{ title: "Última semana", value: "Last week" },
	{ title: "Último mês", value: "Last month" },
	{ title: "Último trimestre", value: "Last quarter" },
	{ title: "Último ano", value: "Last year" },
];

/**
 * Granularidades possíveis para o range de data escolhido.
 *
 * @type {{title: string, value?: string}[]}
 */
const localGranularityRanges = [
	{ title: "Várias granularidades", value: "VARIA" },
	{ title: "Sem agrupamento", value: undefined },
	{ title: "Dia", value: "day" },
	{ title: "Hora", value: "hour" },
	{ title: "Semanas", value: "week" },
	{ title: "Mês", value: "month" },
	{ title: "Ano", value: "year" },
];

/**
 * @description Função que retorna qual é o range atual em comum entre todos os itens do dashboard.
 */
function getCurrentRanges(dashboardItems) {
	//NOTE: Monta o objeto de retorno com valores default
	const dataToReturn = {
		dateRange: localDateRanges[0],
		granularityRange: localGranularityRanges[0],
	};

	//NOTE: Se dashboardItems for array e tiver itens continuamos
	if (isArray(dashboardItems) && dashboardItems.length) {
		//NOTE: Para cada item, precisamos mapear seu valor para um dos dateRanges e granularityRanges que temos registrados no local.
		const mappedRanges = dashboardItems.map(item => {
			/**
			 * @description As dimensões do item atual
			 * @type {{dateRange: string|string[], dimension: string, granularity?: string}|undefined}
			 */
			const timeDimension = get(item, ["vizState", "query", "timeDimensions", 0]);

			/**
			 * @description um dateRange que será retornado da função
			 * @default undefined
			 * @type {{title: string, value?: string|string[]}}
			 */
			let dateRange;

			/**
			 * @description um granularityRange que será retornado da função
			 * @default undefined
			 * @type {string|undefined}
			 */
			let granularityRange;

			//NOTE: Se o dateRange avaliado for array, então tratamos ele como se houve-se datas dentro de si.
			if (isArray(timeDimension?.dateRange)) {
				const initialDate = moment(timeDimension.dateRange[0]);
				const finalDate = moment(timeDimension.dateRange[1]);

				const title = `${initialDate.format("DD/MM/YYYY")} até ${finalDate.format("DD/MM/YYYY")}`;

				dateRange = { title, value: timeDimension?.dateRange };
			} else {
				//NOTE: Se não for array, só procuramos a correspondência nos array local de dateRanges
				dateRange = localDateRanges.find(
					range => range?.value === timeDimension?.dateRange
				);
			}

			//NOTE: Fazemos a mesma coisa com a granularidade.
			granularityRange = localGranularityRanges.find(
				range => range?.value === timeDimension?.granularity
			);

			//NOTE: Ao final retorna os dados de range já mapeados par ao uso local.
			return { dateRange, granularityRange };
		});

		//NOTE: Se todos os dados mapeados, forem iguais, podemos usar o valor dele como o default de todos os itens.
		if (
			mappedRanges.every(
				range =>
					range.dateRange?.value?.toString() ===
					mappedRanges[0].dateRange?.value?.toString()
			)
		) {
			dataToReturn.dateRange = mappedRanges[0]?.dateRange || dataToReturn.dateRange;
		}

		//NOTE: Se todos os dados mapeados, forem iguais, podemos usar o valor dele como o default de todos os itens.
		if (
			mappedRanges.every(
				range => range.granularityRange?.value === mappedRanges[0].granularityRange?.value
			)
		) {
			dataToReturn.granularityRange =
				mappedRanges[0]?.granularityRange || dataToReturn.granularityRange;
		}
	}

	return dataToReturn;
}

/**
 * Renderiza um seletor de data para mudar todas as dimensões de data nas queries dos gráficos do dashboard.
 */
export function ChartDateChanger({ dashboardItems }) {
	const [dateRange, setDateRange] = useState(getCurrentRanges(dashboardItems).dateRange);
	const [granularityRange, setGranularityRange] = useState(
		getCurrentRanges(dashboardItems).granularityRange
	);

	const [updateDashboardItems] = useMutation(UPDATE_DASHBOARD_ITEMS, {
		refetchQueries: [
			{
				query: GET_DASHBOARD_ITEMS,
			},
		],
	});

	function dateRangeMenu(onClick, items) {
		return (
			<Menu>
				{items.map(m => {
					return m.value === "VARIA" ? null : (
						<Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
							{m.title || m.value}
						</Menu.Item>
					);
				})}
			</Menu>
		);
	}

	async function handleChangeTimeDimensionsQuery(currentDateRange, currentGranularityRange) {
		if (currentDateRange.value === "Custom") return;

		//NOTE: Atualiza todos os itens do dashboard.
		const updatedDashboardItems = dashboardItems.map(item => {
			//NOTE: Altera a timeDimensions e granularity do item.
			const updatedObject = update(item, {
				vizState: {
					query: {
						timeDimensions: {
							0: {
								dateRange: {
									$apply: dateRange => {
										return currentDateRange?.value === "VARIA"
											? dateRange
											: currentDateRange?.value;
									},
								},
								granularity: {
									$apply: granularityRange => {
										return currentGranularityRange?.value === "VARIA"
											? granularityRange
											: currentGranularityRange?.value;
									},
								},
							},
						},
					},
				},
			});

			//NOTE: Torna o vizState em uma string
			updatedObject.vizState = JSON.stringify(updatedObject.vizState);

			//NOTE: Remove campos desnecessários para a atualização
			return omitProp(updatedObject, ["layout", "__typename", "name"]);
		});

		//NOTE: Executa uma mutação atualizando todos os itens do dashboard para uma query com o mesmo time dimension
		await updateDashboardItems({
			variables: {
				items: updatedDashboardItems,
			},
		});
	}

	/**
	 * @description Função para controlar a data do seletor customizado.
	 * @param {import("antd/lib/date-picker/interface").RangePickerValue} date
	 */
	function handleChangeDatePicker(date) {
		if (date[0] && date[1]) {
			const initialDate = date[0];
			const finalDate = date[1];

			const title = `${initialDate.format("DD/MM/YYYY")} até ${finalDate.format("DD/MM/YYYY")}`;

			const dateRange = {
				title,
				value: [initialDate.format("YYYY-MM-DD"), finalDate.format("YYYY-MM-DD")],
			};

			setDateRange(dateRange);
			handleChangeTimeDimensionsQuery(dateRange, granularityRange);
		}
	}

	return (
		<Container>
			<Content>
				<Title>Filtro de data geral</Title>
				<FormFieldWrapper>
					<InputWrapper>
						<InputLabel>Data: </InputLabel>
						<RangeSelector
							type="selected"
							overlay={dateRangeMenu(dateRange => {
								setDateRange(dateRange);
								handleChangeTimeDimensionsQuery(dateRange, granularityRange);
							}, localDateRanges)}
							key={`general-date-range`}>
							{dateRange.title}
						</RangeSelector>
					</InputWrapper>
					{dateRange.value === "Custom" && (
						<InputWrapper>
							<InputLabel>Selecione uma data:</InputLabel>
							<RangeCalendarSelector
								className="dataPickerCustom"
								onChange={handleChangeDatePicker}
								picker="month"
							/>
						</InputWrapper>
					)}
					<InputWrapper>
						<InputLabel>Agrupamento:</InputLabel>
						<RangeSelector
							type="selected"
							overlay={dateRangeMenu(granularityRange => {
								setGranularityRange(granularityRange);
								handleChangeTimeDimensionsQuery(dateRange, granularityRange);
							}, localGranularityRanges)}
							key={`general-date-granularity`}>
							{granularityRange.title}
						</RangeSelector>
					</InputWrapper>
				</FormFieldWrapper>
			</Content>
		</Container>
	);
}
