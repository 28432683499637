import React, { useMemo } from "react";
import { ChartDateChanger } from "../ChartDateChanger";
import { DashboardGrid } from "../DashboardGrid";
import { DashboardItem } from "../DashboardItem";
import styled from "styled-components";

const getDefaultLayoutPositions = i => ({
	x: i.layout.x || 0,
	y: i.layout.y || 0,
	w: i.layout.w || 4,
	h: i.layout.h || 8,
});

const DraggableContainer = styled.div`
	z-index: 10;

	&:hover,
	&:active {
		.react-resizable-handle {
			opacity: 1;
		}
	}

	.react-resizable-handle {
		margin: 10px;
		opacity: 0;

		transition: opacity 0.3s;
	}
`;

/**
 * @description Componente que representa o conteúdo do dashboard.
 */
export function DashboardContent({ dashboardItems }) {
	const memoizedDashboardItems = useMemo(() => {
		return dashboardItems.map(item => {
			return (
				<DraggableContainer data-grid={getDefaultLayoutPositions(item)} key={item.id}>
					<DashboardItem item={item} />
				</DraggableContainer>
			);
		});
	}, [dashboardItems]);

	return (
		<div>
			<ChartDateChanger dashboardItems={dashboardItems} />
			<DashboardGrid dashboardItems={dashboardItems}>{memoizedDashboardItems}</DashboardGrid>
		</div>
	);
}
