import numeral from "numeral";

if (numeral.locales.br === undefined) {
	numeral.register("locale", "br", {
		delimiters: {
			thousands: ".",
			decimal: ",",
		},
		abbreviations: {
			thousand: "m",
			million: "M",
			billion: "B",
			trillion: "T",
		},
		ordinal: function (number) {
			const b = number % 10;
			return ~~((number % 100) / 10) === 1
				? "th"
				: b === 1
					? "st"
					: b === 2
						? "nd"
						: b === 3
							? "rd"
							: "th";
		},
		currency: {
			symbol: "R$",
		},
	});
}

numeral.locale("br");

/**
 * @description Função para formatar um número de maneira curta (0,00 para float e 0 para integer).
 * @param {Number} number Valor que será formatado.
 * @param {boolean} [hiddenZero=false] Determina se o zero será oculto se for passado em `number`.
 */
export function fShortenNumber(number, hiddenZero = true) {
	if (hiddenZero === true && number === 0) {
		return "";
	}
	return numeral(number).format(Number.isInteger(number) ? "0" : "0.00");
}

/**
 * @description Função para formatar uma número de maneira curta e sendo em porcentagem (0,00% para float e 0% para integer).
 * @param {Number} number Valor que será formatado.
 * @param {boolean} [hiddenZero=false] Determina se o zero será oculto se for passado em `number`.
 */
export function fShortenPercent(number, hiddenZero = true) {
	const percentSymbol = hiddenZero && number === 0 ? "" : "%";

	return fShortenNumber(number, hiddenZero) + percentSymbol;
}
