import axios from "axios";
import jwt from "jsonwebtoken";
import { getRandomNumber } from "toolkit-extra";
import { authApi } from "../../constant";

/**
 * @description Serviço para tratar da autenticação do usuário.
 */
export class AuthService {
	/**
	 * @type {AuthService}
	 */
	static instance;

	userId = "";
	appId = "";
	restrictions;
	tokenJwt = "";

	static getInstance() {
		if (!AuthService.instance) {
			AuthService.instance = new AuthService();
		}
		return AuthService.instance;
	}

	/**
	 * @description Método para retornar os dados de autenticação.
	 */
	getAuthData() {
		if (!this.tokenJwt) throw new Error("Usuário não conectado.");

		return {
			userId: this.userId,
			appId: this.appId,
			restrictions: this.restrictions,
		};
	}

	/**
	 * @description Método que autentica o usuário.
	 */
	async authenticateUser() {
		let decodedJwt = null;

		//NOTE: Pega o token da URL utilizando regex
		const regexToGetQueryOnSearchParams = /\?(q=[^&]*)/g;
		const searchParamsResult = regexToGetQueryOnSearchParams.exec(window.location.href);

		//NOTE: Se o token existir na URL, guarda ele em uma variável para uso posterior.
		const searchParams = new URLSearchParams(searchParamsResult ? searchParamsResult[1] : "");
		const currentTokenInUrl = searchParams.get("q");

		if (process.env.NODE_ENV === "development") {
			this.tokenJwt =
				currentTokenInUrl ||
				window.localStorage.getItem("userToken") ||
				jwt.sign({ id: getRandomNumber(1, 3000).toString(), appId: "pro" }, "secret dev");

			window.localStorage.setItem("userToken", this.tokenJwt);

			decodedJwt = jwt.decode(this.tokenJwt);

			//NOTE: Remove o token da URL e atualiza o histórico somente em desenvolvimento
			window.history.replaceState(
				null,
				null,
				window.location.href.replace(regexToGetQueryOnSearchParams, "")
			);
		} else {
			if (!currentTokenInUrl) {
				throw new Error("Nenhum token encontrado");
			}

			this.tokenJwt = currentTokenInUrl;

			await axios.post(`${authApi}validarToken`, {
				token: this.tokenJwt,
			});

			decodedJwt = jwt.decode(this.tokenJwt);
		}

		this.userId = decodedJwt.id;
		this.appId = decodedJwt.appId;
		this.restrictions = decodedJwt.restricao;

		return decodedJwt;
	}
}
