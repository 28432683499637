import React from "react";
import * as PropTypes from "prop-types";
import { Menu, Icon, Dropdown, Switch } from "antd";

import styled from "styled-components";

const StyledDropdownTrigger = styled.span`
	color: #141d57;
	cursor: pointer;
	margin-left: 13px;

	& > span {
		margin: 0 8px;
	}
`;

const ChartTypes = [
	{ name: "line", title: "Linha", icon: "line-chart" },
	{ name: "area", title: "Área", icon: "area-chart" },
	{ name: "bar", title: "Barra", icon: "bar-chart" },
	{ name: "pie", title: "Pizza", icon: "pie-chart" },
	{ name: "table", title: "Tabela", icon: "table" },
	{ name: "number", title: "Número", icon: "info-circle" },
];

const SelectChartType = ({
	chartType,
	updateChartType,
	stack,
	setStack,
	setShowChatValuesLabel,
	showChartValuesLabel,
	percentual,
	setPercentual,
	hidePie,
}) => {
	const types = ChartTypes.filter(m => {
		if (hidePie) {
			if (m.title !== "Pizza") return m;

			return false;
		} else {
			return m;
		}
	});
	const menu = (
		<Menu>
			{types.map(m => (
				<Menu.Item key={m.title} onClick={() => updateChartType(m.name)}>
					<Icon type={m.icon} />
					{m.title}
				</Menu.Item>
			))}
		</Menu>
	);

	const foundChartType = types.find(t => t.name === chartType);
	return (
		<div>
			<Dropdown
				overlay={menu}
				icon={foundChartType.icon}
				lacement="bottomLeft"
				trigger={["click"]}>
				<StyledDropdownTrigger>
					<Icon type={foundChartType.icon} />
					<span>{foundChartType.title}</span>
					<Icon type="caret-down" />
				</StyledDropdownTrigger>
			</Dropdown>
			<span style={{ marginLeft: 20, marginRight: 20 }}>|</span>
			<Switch
				checkedChildren="Mostrar valores"
				unCheckedChildren="Mostrar valores"
				onChange={checked => {
					setShowChatValuesLabel(checked).then(() => {
						updateChartType(chartType);
					});
				}}
				checked={showChartValuesLabel}
			/>
			{chartType === "bar" ? (
				<>
					<span style={{ marginLeft: 20, marginRight: 20 }}>|</span>
					<Switch
						checkedChildren="Empilhar"
						unCheckedChildren="Empilhar"
						onChange={checked => {
							setStack(checked).then(() => {
								updateChartType(chartType);
							});
						}}
						checked={stack}
					/>
				</>
			) : null}
			{chartType === "pie" ? (
				<>
					<span style={{ marginLeft: 20, marginRight: 20 }}>|</span>
					<Switch
						checkedChildren="Percentual"
						unCheckedChildren="Percentual"
						onChange={checked => {
							setPercentual(checked).then(() => {
								updateChartType(chartType);
							});
						}}
						checked={percentual}
					/>
				</>
			) : null}
		</div>
	);
};

SelectChartType.propTypes = {
	chartType: PropTypes.string.isRequired,
	updateChartType: PropTypes.func.isRequired,
};

export default SelectChartType;
