import React from "react";
import DashboardPage from "../../pages/DashboardPage";
import ExplorePage from "../../pages/ExplorePage";
import { Layout } from "antd";

import { createBrowserRouter, RouterProvider as ReactRouterDomProvider } from "react-router-dom";

const router = createBrowserRouter([
	{
		path: "/",
		element: <DashboardPage />,
	},
	{
		path: "/explore",
		element: <ExplorePage />,
	},
]);

/**
 * @description Um provider que controla o roteamento da aplicação.
 */
export function RouterProvider() {
	return (
		<Layout style={{ height: "100%" }}>
			<Layout.Content>
				<ReactRouterDomProvider router={router} />
			</Layout.Content>
		</Layout>
	);
}
