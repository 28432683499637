import { ApolloProvider } from "@apollo/react-hooks";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { AuthContextProvider } from "./contexts/AuthContext";
import apolloClient from "./graphql/client";
import { AuthGuard } from "./guards/AuthGuard";
import { RouterProvider } from "./providers/RouterProvider";
import * as serviceWorker from "./serviceWorker";
import { CubeJsProvider } from "./providers/CubeJsProvider";

import "./theme/index.less";
import "react-toastify/dist/ReactToastify.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "simplebar-react/dist/simplebar.min.css";

const App = () => {
	return (
		<AuthContextProvider>
			<AuthGuard>
				<CubeJsProvider>
					<ApolloProvider client={apolloClient}>
						<RouterProvider />
					</ApolloProvider>
				</CubeJsProvider>
			</AuthGuard>
			<ToastContainer />
		</AuthContextProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
