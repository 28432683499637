import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";

const AuthContextContext = createContext({});

/**
 * @description Contexto para tratar a autenticação do usuário.
 */
export function AuthContextProvider({ children }) {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const handleAuthenticate = useCallback(async () => {
		try {
			setIsLoading(true);

			await AuthService.getInstance().authenticateUser();

			setIsAuthenticated(true);
		} catch (error) {
			setError(error);
			setIsAuthenticated(false);

			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		handleAuthenticate();
	}, [handleAuthenticate]);

	return (
		<AuthContextContext.Provider value={{ isLoading, error, isAuthenticated }}>
			{children}
		</AuthContextContext.Provider>
	);
}

/**
 * @description Hook para acessar o contexto da autenticação.
 * @returns {{isLoading: boolean, isAuthenticated:boolean, error: null|Error}} Dados do contexto
 */
export function useAuthContext() {
	const contextState = useContext(AuthContextContext);

	if (!contextState) {
		throw new Error(
			"O hook não tem acesso ao contexto. Envolva os componentes necessários em um AuthContextProvider"
		);
	}

	return contextState;
}
