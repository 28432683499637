import React, { useMemo, useState } from "react";
import { Modal, Input, Button } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { GET_DASHBOARD_ITEMS } from "../../graphql/queries";
import { CREATE_DASHBOARD_ITEM, UPDATE_DASHBOARD_ITEM } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { isQueryPresent } from "@cubejs-client/react";

/**
 * @description Modal para criar/atualizar um item da dashboard.
 */
export function UpsertDashboardItemModal({ itemId, finalVizState, oldDashboardItemTitle }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [dashboardItemTitle, setDashboardItemTitle] = useState(oldDashboardItemTitle);

	const navigate = useNavigate();

	const [upsertDashboardItemMutate, upsertDashboardItemMutateState] = useMutation(
		itemId ? UPDATE_DASHBOARD_ITEM : CREATE_DASHBOARD_ITEM,
		{
			refetchQueries: [
				{
					query: GET_DASHBOARD_ITEMS,
				},
			],
		}
	);

	async function handleConfirmUpsert() {
		setIsModalOpen(false);

		await upsertDashboardItemMutate({
			variables: {
				id: itemId,
				input: {
					vizState: JSON.stringify(finalVizState),
					name: dashboardItemTitle,
				},
			},
		});

		navigate(`/`);
	}

	const isQueryInvalid = useMemo(() => {
		return finalVizState.query ? !isQueryPresent(finalVizState.query) : true;
	}, [finalVizState.query]);

	return (
		<>
			<Modal
				title="Salvar Gráfico"
				visible={isModalOpen}
				onOk={handleConfirmUpsert}
				onCancel={() => setIsModalOpen(false)}>
				<Input
					placeholder="Nome do item na Dashboard"
					value={dashboardItemTitle}
					onChange={e => setDashboardItemTitle(e.target.value)}
				/>
			</Modal>
			<Button
				type="primary"
				loading={upsertDashboardItemMutateState.loading}
				disabled={isQueryInvalid}
				onClick={() => setIsModalOpen(true)}>
				{itemId ? "Atualizar" : "Adicionar à Dashboard"}
			</Button>
		</>
	);
}
