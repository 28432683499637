import React, { useState } from "react";
import { Button, message } from "antd";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { host } from "../../constant";
import { useMountedState } from "react-use";

/**
 * @description Renderiza um botão para exportar os dados de uma consulta para um arquivo Excel.
 */
export function ExportExcelButton({ resultSet, base, vizState, stack }) {
	const checkIsMounted = useMountedState();
	const [isLoading, setIsLoading] = useState(false);

	const columns = resultSet.tableColumns().map(column => {
		let title = column.title.replace(base.shortTitle + " Bi", "").replace(base.shortTitle, "");
		return title;
	});

	const tableData = resultSet.tablePivot().map(row => {
		return _.map(row, (v, i) => {
			if (i.toUpperCase().includes("DATA")) {
				return moment(v).format("YYYY/MM/DD");
			} else {
				return v;
			}
		});
	});

	const value = [columns, ...tableData];

	async function handleExportChart() {
		const processingMessageKey = "processingMessage";

		const finishProcessingMessage = message.loading({
			content: "Aguarde um momento, estamos gerando o arquivo...",
			key: processingMessageKey,
			duration: 0,
		});

		try {
			setIsLoading(true);

			const charts = {
				line: [{ type: "line" }],
				pie: [{ type: "pie" }],
				bar: [{ type: "column", ...(stack ? { subtype: "stacked" } : null) }],
				area: [{ type: "area" }],
				default: [
					{ type: "line" },
					{ type: "pie" },
					{ type: "column", subtype: "stacked" },
					{ type: "area" },
				],
			};

			const chartT = charts[vizState.chartType]
				? charts[vizState.chartType]
				: charts["default"];

			const response = await axios.post(
				`${host}/biback/exportarExcel`,
				{ data: value, chartType: JSON.stringify(chartT) },
				{ responseType: "blob", timeout: 120000 }
			);

			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				// IE variant
				window.navigator.msSaveOrOpenBlob(
					new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					}),
					"fileName.xlsx"
				);
			} else {
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					})
				);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute(
					"download",
					`${base.shortTitle} ${moment().format("YYYY-MM-DD-Ss")}.xlsx`
				);
				document.body.appendChild(link);
				link.click();
			}
		} catch (error) {
			console.error(error);
			message.error({
				content: "Ocorreu um erro ao exportar. Tente novamente mais tarde.",
				key: processingMessageKey,
			});
		} finally {
			checkIsMounted() && setIsLoading(false);
			finishProcessingMessage();
		}
	}

	return (
		<Button
			onClick={handleExportChart}
			type="primary"
			icon="file-excel"
			size={"large"}
			loading={isLoading}>
			Exportar
		</Button>
	);
}
