import moment from "moment";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { fShortenNumber } from "../../../utils/numeral";

const xAxisFormatter = item => {
	if (moment(item).isValid()) {
		return moment(item).format("DD/MM/YYYY");
	} else {
		return item;
	}
};

/**
 * @description Renderiza um wrapper de gráfico cartesiano genérico.
 */
export function CartesianChart({ resultSet, children, ChartComponent }) {
	return (
		<AutoSizer>
			{({ width, height }) => (
				<ChartComponent data={resultSet.chartPivot()} width={width} height={height}>
					<XAxis
						axisLine={false}
						tickLine={false}
						tickFormatter={xAxisFormatter}
						dataKey="x"
						minTickGap={20}
					/>
					<YAxis axisLine={false} tickLine={false} tickFormatter={fShortenNumber} />
					<CartesianGrid vertical={false} />
					{children}
					<Legend
						wrapperStyle={{
							overflowX: "hidden",
							overflowY: "scroll",
							maxHeight: 70,
						}}
					/>
					<Tooltip labelFormatter={xAxisFormatter} formatter={fShortenNumber} />
				</ChartComponent>
			)}
		</AutoSizer>
	);
}
