import { useQuery } from "@apollo/react-hooks";
import { Alert, Button } from "antd";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { DashboardContent } from "../../components/DashboardContent";
import { DashboardEmpty } from "../../components/DashboardEmpty";
import { LoadingFullPage } from "../../components/LoadingFullPage";
import PageHeader from "../../components/PageHeader";
import { GET_DASHBOARD_ITEMS } from "../../graphql/queries";

const deserializeItem = i => ({
	...i,
	layout: JSON.parse(i.layout) || {},
	vizState: JSON.parse(i.vizState),
});

export default function DashboardPage() {
	const { loading, error, data } = useQuery(GET_DASHBOARD_ITEMS);

	const dashboardItems = useMemo(
		() => (data ? data.dashboardItems.map(deserializeItem) : []),
		[data]
	);

	if (loading) {
		return <LoadingFullPage />;
	}

	if (error) {
		return (
			<Alert
				message="Ocorreu um erro ao carregar o seu gráfico."
				description={error.toString()}
				type="error"
			/>
		);
	}

	return (
		<main>
			<PageHeader
				title={"Dashboard"}
				actionButton={
					<Link to="/explore">
						<Button type="primary">Adicionar Gráfico</Button>
					</Link>
				}
			/>
			{dashboardItems.length ? (
				<DashboardContent dashboardItems={dashboardItems} />
			) : (
				<DashboardEmpty />
			)}
		</main>
	);
}
