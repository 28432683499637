import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader/index.jsx";
import ExploreQueryBuilder from "../../components/QueryBuilder/ExploreQueryBuilder.js";
import { UpsertDashboardItemModal } from "../../components/UpsertDashboardItemModal/index.jsx";
import { GET_DASHBOARD_ITEM } from "../../graphql/queries.js";

export default function ExplorePage() {
	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const itemId = params.get("itemId");

	const { loading, error, data } = useQuery(GET_DASHBOARD_ITEM, {
		variables: {
			id: itemId,
		},
		skip: !itemId,
	});

	const [vizState, setVizState] = useState(null);

	if (loading) {
		return <Spin />;
	}

	if (error) {
		return <Alert type="error" message={error.toString()} />;
	}

	const vizStateToUpdate =
		vizState || (data?.dashboardItem?.vizState ? JSON.parse(data.dashboardItem.vizState) : {});

	return (
		<div>
			<PageHeader
				hasGoBack
				title={`${itemId ? "Atualizar a" : "Adicionar à"} Dashboard`}
				actionButton={
					<UpsertDashboardItemModal
						finalVizState={vizStateToUpdate}
						itemId={itemId}
						oldDashboardItemTitle={data?.dashboardItem?.name || "Novo Gráfico"}
					/>
				}
			/>
			<ExploreQueryBuilder vizState={vizStateToUpdate} setVizState={setVizState} />
		</div>
	);
}
