/**
 * 52 Cores padronizadas para uso nos gráficos do dashboard.
 */
export const defaultChatColors = [
	"#2765ff",
	"#29abe2",
	"#9b43ff",
	"#4682B4",
	"#DC143C",
	"#9370DB",
	"#CD853F",
	"#FA8072",
	"#BDB76B",
	"#FFD700",
	"#800080",
	"#E9967A",
	"#32CD32",
	"#00e6ff",
	"#D2691E",
	"#EE82EE",
	"#DDA0DD",
	"#A0522D",
	"#5F9EA0",
	"#FF6347",
	"#ADFF2F",
	"#FF00FF",
	"#FFA07A",
	"#556B2F",
	"#6A5ACD",
	"#9932CC",
	"#F4A460",
	"#BA55D3",
	"#228B22",
	"#4B0082",
	"#F0E68C",
	"#6495ED",
	"#B22222",
	"#A52A2A",
	"#1E90FF",
	"#DAA520",
	"#A52A2A",
	"#8B008B",
	"#66CDAA",
	"#8A2BE2",
	"#6B8E23",
	"#7B68EE",
	"#8B4513",
	"#7501c0",
	"#48e570",
	"#8B0000",
	"#9ACD32",
	"#8A2BE2",
	"#808000",
	"#A0522D",
	"#FF4500",
	"#006400",
	"#4169E1",
	"#8B008B",
	"#CD5C5C",
	"#7B68EE",
];

/**
 * @description Função para gerar uma cor aleatória hexadecimal;
 * @returns {string} Hexadecimal da cor;
 */
export function generateHexColor() {
	return "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
}

/**
 * @description Função que pega uma cor da lista de cores pré-definidas para gráficos ou uma cor aleatória se o index for maior que o tamanho da lista.
 * @param {number} index
 * @returns {string} Uma cor hexadecimal
 */
export function getChartColorByIndex(index) {
	return defaultChatColors[index] || generateHexColor();
}
