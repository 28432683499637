import { Col, Row, Statistic } from "antd";
import React from "react";

/**
 * Renderiza um gráfico com um valor único para uso na aplicação.
 */
export function DefaultSingleNumberChart({ resultSet }) {
	return (
		<Row
			type="flex"
			justify="center"
			align="middle"
			style={{
				height: "100%",
				textAlign: "center",
			}}>
			<Col>
				{resultSet.seriesNames().map(s => (
					<Statistic value={resultSet.totalRow()[s.key]} title={s.title} key={s.key} />
				))}
			</Col>
		</Row>
	);
}
