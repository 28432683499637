import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

/**
 * @description Renderiza um componente que simula um loading em spinner centralizado.
 */
export function CenterLoading() {
	return (
		<Container>
			<Spin size="large" />
		</Container>
	);
}
