import { Button, Icon } from "antd";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 1rem;
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 1.2rem;
`;

const MessageWrapper = styled.div`
	text-align: center;
`;

const Description = styled.div``;

const RetryButton = styled(Button)``;

const ErrorIcon = styled(Icon)`
	font-size: 3rem;
	color: #fa4f57 !important;
`;

/**
 * @description Componente para mostrar uma mensagem de erro com botão para tentar novamente.
 */
export function ErrorInfoWithRetry({ error, retryFn }) {
	return (
		<Container>
			<ErrorIcon type="alert" theme="filled"></ErrorIcon>
			<MessageWrapper>
				<Title>Ops, algo deu errado...</Title>
				{error?.message && <Description>{error.message}</Description>}
			</MessageWrapper>

			{retryFn && <RetryButton onClick={retryFn}>Repetir</RetryButton>}
		</Container>
	);
}
