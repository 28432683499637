import { useMutation } from "@apollo/react-hooks";
import { Switch, message } from "antd";
import update from "immutability-helper";
import React, { useState } from "react";
import { useMountedState } from "react-use";
import { UPDATE_DASHBOARD_ITEM } from "../../graphql/mutations";
import { GET_DASHBOARD_ITEMS } from "../../graphql/queries";
import { ChartRenderer } from "../ChartRenderer";
import { DashboardItemDropdown } from "../DashboardItemDropdown";
import { DefaultCard } from "../DefaultCard";
import { ChartTitle, Content, Header, SwitchWrapper } from "./styles";

/**
 * @description Componente responsável por renderizar um card com um gráfico na dashboard.
 */
export function DashboardItem({ item }) {
	/**
	 * Função que determina se o componente está montado.
	 */
	const isMounted = useMountedState();

	const [isLoading, setIsLoading] = useState(false);

	const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
		refetchQueries: [
			{
				query: GET_DASHBOARD_ITEMS,
			},
		],
	});

	/**
	 * @description Função para controlar o estado de `isLoading` de maneira que não haja `Memory leak`.
	 * @param {Boolean} value Novo valor para `isLoading`;
	 */
	function handleSetIsLoading(value) {
		isMounted() && setIsLoading(value);
	}

	/**
	 * @description Função que atualiza o valor de `vizState.showChartValuesLabel` para cada gráfico individual na dashboard.
	 * @param {Boolean} value Novo valor para `vizState.showChartValuesLabel`;
	 */
	async function handleChangeShowChatValuesLabel(value) {
		try {
			handleSetIsLoading(true);
			const editedVizState = update(item.vizState, {
				showChartValuesLabel: {
					$set: value,
				},
			});
			await updateDashboardItem({
				variables: {
					id: item.id,
					input: {
						vizState: JSON.stringify(editedVizState),
					},
				},
			});
		} catch (error) {
			console.error(error);
			message.error("Ocorreu um erro ao atualizar o gráfico. Tente novamente mais tarde.");
		} finally {
			handleSetIsLoading(false);
		}
	}

	/**
	 * @description Função que atualiza o valor de `vizState.percentual` para cada gráfico individual na dashboard.
	 * @param {Boolean} value Novo valor para `vizState.percentual`;
	 */
	async function handleChangePercentStatus(value) {
		try {
			handleSetIsLoading(true);
			const editedVizState = update(item.vizState, {
				percentual: {
					$set: value,
				},
			});
			await updateDashboardItem({
				variables: {
					id: item.id,
					input: {
						vizState: JSON.stringify(editedVizState),
					},
				},
			});
		} catch (error) {
			console.error(error);
			message.error("Ocorreu um erro ao atualizar o gráfico. Tente novamente mais tarde.");
		} finally {
			handleSetIsLoading(false);
		}
	}

	const isPieChart = item.vizState.chartType === "pie";
	const isTable = item.vizState.chartType === "table";
	const isNumber = item.vizState.chartType === "number";

	const showSwitches = !isTable && !isNumber;

	return (
		<DefaultCard>
			<Header withMargin={isTable} isNumberChart={isNumber}>
				<div>
					<ChartTitle>{item.name}</ChartTitle>
					{showSwitches && (
						<SwitchWrapper>
							<Switch
								checkedChildren="Mostrar valores"
								unCheckedChildren="Mostrar valores"
								loading={isLoading}
								onChange={handleChangeShowChatValuesLabel}
								checked={item.vizState.showChartValuesLabel}
							/>
							{isPieChart && (
								<Switch
									checkedChildren="Percentual"
									unCheckedChildren="Percentual"
									loading={isLoading}
									onChange={handleChangePercentStatus}
									checked={item.vizState.percentual}
								/>
							)}
						</SwitchWrapper>
					)}
				</div>
				<DashboardItemDropdown itemId={item.id} />
			</Header>

			<Content noPadding={isTable}>
				<ChartRenderer
					vizState={item.vizState}
					base={item.vizState.base}
					stack={item.vizState.stack}
					showChartValuesLabel={item.vizState.showChartValuesLabel}
					percentual={!!item.vizState.percentual}
				/>
			</Content>
		</DefaultCard>
	);
}
