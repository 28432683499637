/**
 * Regex para validar datas no formato `YYYY-MM-DD`;
 * @obs Um formato correto não significa que a data seja válida;
 */
export const ISO_DATE = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Regex para validar datas no formato `YYYY-MM-DDTHH:MM:SS.SSS`;
 * @obs Um formato correto não significa que a data seja válida;
 */
export const ISO_DATE_TIME = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

/**
 * Regex para validar datas no formato `YYYY-MM-DD` ou `YYYY-MM-DDTHH:MM:SS.SSS`;
 * @obs Um formato correto não significa que a data seja válida;
 */
export const ISO_DATE_AND_ISO_DATE_TIME = new RegExp(
	"(" + ISO_DATE.source + ")|(" + ISO_DATE_TIME.source + ")"
);
