import React from "react";
import { Area, AreaChart } from "recharts";
import { getChartColorByIndex } from "../../../theme/Chart";
import { fDateForChart } from "../../../utils/Chart";
import { fShortenNumber } from "../../../utils/numeral";
import { CartesianChart } from "../CartesianChart";

/**
 * Renderiza um gráfico de área padrão para uso na aplicação.
 */
export function DefaultAreaChart({ resultSet, showChartValuesLabel }) {
	return (
		<CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
			{resultSet.seriesNames().map((series, i) => {
				let color = getChartColorByIndex(i);
				let valor = series.title.split(",");
				return (
					<Area
						key={series.key}
						label={
							showChartValuesLabel
								? {
										position: "top",
										fontSize: 10,
										formatter: fShortenNumber,
									}
								: undefined
						}
						stackId="a"
						dataKey={series.key}
						name={fDateForChart(valor[0])}
						stroke={color}
						fill={color}
					/>
				);
			})}
		</CartesianChart>
	);
}
