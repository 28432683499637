import React from "react";
import { Spin } from "antd";
/**
 * @description Renderiza um componente que simula um loading em spinner para ocupar a tela toda.
 */
export function LoadingFullPage() {
	return (
		<div
			style={{
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<Spin />
			<p>Carregando dados</p>
		</div>
	);
}
