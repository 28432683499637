import React from "react";
import { Link } from "react-router-dom";
import { Container, GoBackButton, LeftContent, Logo, Title } from "./styles";

/**
 * @description Renderiza um componente de cabeçalho para utilizar em qualquer página
 */
export function PageHeader({ title, hasGoBack, actionButton }) {
	return (
		<Container>
			<LeftContent>
				{hasGoBack && (
					<Link to="..">
						<GoBackButton icon="left" size="small"></GoBackButton>
					</Link>
				)}

				<Link to="/">
					<Logo src="/images/logo.svg"></Logo>
				</Link>

				<Title>{title}</Title>
			</LeftContent>
			{actionButton}
		</Container>
	);
}

export default PageHeader;
