import { useMutation } from "@apollo/react-hooks";
import React, { useState } from "react";
import GridLayout, { WidthProvider } from "react-grid-layout";
import styled from "styled-components";
import { UPDATE_DASHBOARD_ITEMS } from "../../graphql/mutations";

const Container = styled(WidthProvider(GridLayout))`
	margin: 0.3rem;

	.react-grid-placeholder {
		background-color: #0565f4 !important;
		border-radius: 0.5rem;
	}
`;

export function DashboardGrid({ children, dashboardItems }) {
	const [isDragging, setIsDragging] = useState(false);

	const [updateDashboardItems] = useMutation(UPDATE_DASHBOARD_ITEMS);

	async function onLayoutChange(newLayout) {
		//NOTE: Mapeia cada item da dashboard para o novo layout
		//Procura o layout associado ao item, se não encontra-lo usa o que já está setado, se não, utiliza o novo mapeado pela lib de grid.
		const updatedDashboardItems = dashboardItems.map(dashboardItem => {
			const layout = newLayout.find(layout => String(layout.i) === String(dashboardItem.id));

			let newLayoutToUpdate = JSON.stringify(dashboardItem.layout);

			if (layout) {
				newLayoutToUpdate = JSON.stringify({
					x: layout?.x,
					y: layout?.y,
					w: layout?.w,
					h: layout?.h,
				});
			}

			return { id: dashboardItem.id, layout: newLayoutToUpdate };
		});

		//NOTE: Atualiza todos os itens do dashboard
		await updateDashboardItems({
			variables: { items: updatedDashboardItems },
		});
	}

	return (
		<Container
			onDragStart={() => setIsDragging(true)}
			onDragStop={() => setIsDragging(false)}
			onResizeStart={() => setIsDragging(true)}
			onResizeStop={() => setIsDragging(false)}
			onLayoutChange={onLayoutChange}
			isDragging={isDragging}
			resizeHandles={["s", "n", "e", "w", "se"]}
			useCSSTransforms
			margin={[20, 20]}
			rowHeight={7}>
			{children}
		</Container>
	);
}
