import React from "react";
import { CartesianChart } from "../CartesianChart";
import { Line, LineChart } from "recharts";
import { fShortenNumber } from "../../../utils/numeral";
import { fDateForChart } from "../../../utils/Chart";
import { getChartColorByIndex } from "../../../theme/Chart";

/**
 * Renderiza um gráfico de linha padrão para uso na aplicação.
 */
export function DefaultLineChart({ resultSet, showChartValuesLabel }) {
	return (
		<CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
			{resultSet.seriesNames().map((series, i) => {
				let valor = series.title.split(",");
				return (
					<Line
						key={series.key}
						label={
							showChartValuesLabel
								? {
										position: "top",
										fontSize: 10,
										formatter: fShortenNumber,
									}
								: undefined
						}
						stackId="a"
						dataKey={series.key}
						name={fDateForChart(valor[0])}
						stroke={getChartColorByIndex(i)}
					/>
				);
			})}
		</CartesianChart>
	);
}
