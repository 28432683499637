import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	padding: 1rem 1.5rem;
	align-items: center;
	gap: 1rem;

	@media (max-width: 640px) {
		flex-direction: column;
	}
`;

export const LeftContent = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const GoBackButton = styled(Button)``;

export const Logo = styled.img`
	width: 3rem;
	height: 3rem;
`;

export const Title = styled.h1`
	margin: 0;
	font-weight: 600;
`;
