import React from "react";
import { LoadingFullPage } from "../../components/LoadingFullPage";
import { useAuthContext } from "../../contexts/AuthContext";

/**
 * @description Guardião para garantir que o usuário esteja autenticado.
 */
export function AuthGuard({ children }) {
	const { isLoading, error } = useAuthContext();

	if (error) {
		return (
			<div
				style={{
					height: "100vh",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<p>Você não esta autenticado</p>
			</div>
		);
	}

	if (isLoading) {
		return <LoadingFullPage />;
	}

	return children;
}
