import { useCubeQuery } from "@cubejs-client/react";
import React, { useMemo } from "react";
import { CenterLoading } from "../CenterLoading";
import { DefaultAreaChart } from "../Charts/DefaultAreaChart";
import { DefaultBarChart } from "../Charts/DefaultBarChart";
import { DefaultLineChart } from "../Charts/DefaultLineChart";
import { DefaultPieChart } from "../Charts/DefaultPieChart";
import { DefaultSingleNumberChart } from "../Charts/DefaultSingleNumberChart";
import { DefaultTableChart } from "../Charts/DefaultTableChart";
import "./index.less";
import { ErrorInfoWithRetry } from "../ErrorInfoWithRetry";

/**
 * Objeto chave e valor onde cada chave é um tipo de gráfico e o valor é o componente que representa esse gráfico.
 */
const chartOptions = {
	line: DefaultLineChart,
	bar: DefaultBarChart,
	area: DefaultAreaChart,
	pie: DefaultPieChart,
	table: DefaultTableChart,
	number: DefaultSingleNumberChart,
};

/**
 * @description Componente que busca dados na api renderiza um gráfico dependendo do tipo de consulta passado.
 */
export function ChartRenderer({
	vizState,
	base,
	stack,
	setCubeQueryResult,
	percentual,
	showChartValuesLabel,
}) {
	const { query, chartType } = vizState;

	//NOTE: Escolhe o gráfico pelo tipo.
	//por padrão traz o de barras
	const CurrentChartComponent = useMemo(() => {
		return chartOptions[chartType || "bar"] || chartOptions["bar"];
	}, [chartType]);

	//NOTE: Busca dados na api e seta o estado para fora do componente se necessário
	const renderProps = useCubeQuery(query);
	setCubeQueryResult && setCubeQueryResult(renderProps);

	if (renderProps.error)
		return <ErrorInfoWithRetry retryFn={renderProps.refetch} error={renderProps.error} />;

	if (renderProps.isLoading || !renderProps.resultSet) return <CenterLoading />;

	return (
		<CurrentChartComponent
			base={base}
			stack={stack}
			percentual={percentual}
			showChartValuesLabel={showChartValuesLabel}
			resultSet={renderProps.resultSet}
		/>
	);
}
