export const PROD_HOST = "https://tradepro-cors.herokuapp.com/https://api.tradepro.com.br/";

export const DEV_HOST = "localhost:4000";

export const host = process.env.NODE_ENV === "development" ? `http://${DEV_HOST}` : `${PROD_HOST}`;

export const CUBE_API_SECRET =
	"eea7a4194cbb8f51c6bbb8a3d0cc00f384320f5370363a6fedd4a2780193767b09639ea004d100afe484907816a698aff0f2ec772cf000916826b5532efb2cca";

export const authApi =
	process.env.NODE_ENV === "development"
		? `https://tradepro-cors.herokuapp.com/https://api.tradepro.com.br/tokenauth/`
		: `https://api.tradepro.com.br/tokenauth/`;
