import React from "react";
import * as PropTypes from "prop-types";
import { Menu } from "antd";
import { sortBy, prop } from "ramda";
import ButtonDropdown from "./ButtonDropdown"; // Can't be a Pure Component due to Dropdown lookups overlay component type to set appropriate styles

const sortByTitle = sortBy(prop("title"));

const memberMenu = (onClick, availableMembers) => (
	<Menu style={{ maxHeight: "50vh", overflowY: "auto" }}>
		{availableMembers.length ? (
			sortByTitle(availableMembers).map(m => (
				<Menu.Item key={m.name} onClick={() => onClick(m)}>
					{m.shortTitle}
				</Menu.Item>
			))
		) : (
			<Menu.Item disabled>Nada encontrado</Menu.Item>
		)}
	</Menu>
);

const MemberDropdown = ({ onClick, availableMembers, ...buttonProps }) => (
	<ButtonDropdown overlay={memberMenu(onClick, availableMembers)} {...buttonProps} />
);

MemberDropdown.propTypes = {
	onClick: PropTypes.func.isRequired,
	availableMembers: PropTypes.array.isRequired,
};
export default MemberDropdown;
