import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Menu, DatePicker } from "antd";
import ButtonDropdown from "./ButtonDropdown";
import MemberDropdown from "./MemberDropdown";
import RemoveButtonGroup from "./RemoveButtonGroup";
import MemberGroupTitle from "./MemberGroupTitle";
import PlusIcon from "./PlusIcon";
import styled from "styled-components";
const { RangePicker } = DatePicker;
const granularitiesToPTBR = {
	Day: "Dia",
	Hour: "Hora",
	Week: "Semana",
	Month: "Mês",
	Year: "Ano",
	"w/o grouping": "Sem agrupamento",
};

const DateRanges = [
	{ title: "Todos", value: undefined },
	{ title: "Customizado", value: "Custom" },
	{ title: "Hoje", value: "Today" },
	{ title: "Ontem", value: "Yesterday" },
	{ title: "Esta semana", value: "This week" },
	{ title: "Este mês", value: "This month" },
	{ title: "Este trimestre", value: "This quarter" },
	{ title: "Este ano", value: "This year" },
	{ title: "Últimos 7 dias", value: "Last 7 days" },
	{ title: "Últimos 30 dias", value: "Last 30 days" },
	{ title: "Última semana", value: "Last week" },
	{ title: "Último mês", value: "Last month" },
	{ title: "Último trimestre", value: "Last quarter" },
	{ title: "Último ano", value: "Last year" },
];

const GroupLabel = styled.span`
	font-size: 14px;
	margin: 0 12px;
`;

const TimeGroup = ({ members, availableMembers, addMemberName, updateMethods, title }) => {
	const [custom, openCustom] = useState(false);
	const granularityMenu = (member, onClick) => (
		<Menu>
			{member.granularities.length ? (
				member.granularities.map(m => (
					<Menu.Item key={m.title} onClick={() => onClick(m)}>
						{granularitiesToPTBR[m.title]}
					</Menu.Item>
				))
			) : (
				<Menu.Item disabled>Nada encontrado</Menu.Item>
			)}
		</Menu>
	);

	const dateRangeMenu = onClick => (
		<Menu>
			{DateRanges.map(m => {
				return (
					<Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
						{m.title || m.value}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<div>
			<MemberGroupTitle title={title} />
			{members.map(m => [
				<RemoveButtonGroup
					onRemoveClick={() => updateMethods.remove(m)}
					key={`${m.dimension.name}-member`}>
					<MemberDropdown
						type="selected"
						onClick={updateWith =>
							updateMethods.update(m, { ...m, dimension: updateWith })
						}
						availableMembers={availableMembers}>
						{m.dimension.title}
					</MemberDropdown>
				</RemoveButtonGroup>,
				<GroupLabel key={`${m.dimension.name}-for`}>para</GroupLabel>,
				<ButtonDropdown
					type="time-group"
					overlay={dateRangeMenu(dateRange => {
						if (dateRange.value !== "Custom") {
							openCustom(false);
							return updateMethods.update(m, { ...m, dateRange: dateRange.value });
						} else {
							updateMethods.update(m, { ...m, dateRange: dateRange.value });
							return openCustom(true);
						}
					})}
					key={`${m.dimension.name}-date-range`}>
					{m.dateRange
						? DateRanges.find(d => d.value === m.dateRange)?.title ?? "Customizado"
						: "Todos"}
				</ButtonDropdown>,
				custom ? (
					<RangePicker
						className="dataPickerCustom"
						onChange={v => {
							if (v[0]) {
								updateMethods.update(m, {
									...m,
									dateRange: [
										v[0].format("YYYY-MM-DD"),
										v[1].format("YYYY-MM-DD"),
									],
								});
							}
						}}
						picker="month"
					/>
				) : (
					<></>
				),
				<GroupLabel key={`${m.dimension.name}-by`}>por</GroupLabel>,
				<ButtonDropdown
					type="time-group"
					overlay={granularityMenu(m.dimension, granularity =>
						updateMethods.update(m, { ...m, granularity: granularity.name })
					)}
					key={`${m.dimension.name}-granularity`}>
					{m.dimension.granularities.find(g => g.name === m.granularity) &&
						granularitiesToPTBR[
							m.dimension.granularities.find(g => g.name === m.granularity).title
						]}
				</ButtonDropdown>,
			])}
			{!members.length && (
				<MemberDropdown
					onClick={member => updateMethods.add({ dimension: member, granularity: "day" })}
					availableMembers={availableMembers}
					type="new">
					{addMemberName}
					<PlusIcon />
				</MemberDropdown>
			)}
		</div>
	);
};

TimeGroup.propTypes = {
	members: PropTypes.array.isRequired,
	availableMembers: PropTypes.array.isRequired,
	addMemberName: PropTypes.string.isRequired,
	updateMethods: PropTypes.object.isRequired,
};

export default TimeGroup;
