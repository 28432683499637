import { QueryBuilder } from "@cubejs-client/react";
import { Col, Divider, Row } from "antd";
import { map } from "lista-de-coisas-uteis";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChartRenderer } from "../ChartRenderer";
import { DefaultCard } from "../DefaultCard/index.jsx";
import { ExportExcelButton } from "../ExportExcelButton";
import FilterGroup from "./FilterGroup";
import MemberDropdown from "./MemberDropdown";
import MemberGroup from "./MemberGroup";
import MemberGroupTitle from "./MemberGroupTitle";
import PlusIcon from "./PlusIcon";
import RemoveButtonGroup from "./RemoveButtonGroup";
import SelectChartType from "./SelectChartType";
import TimeGroup from "./TimeGroup";
import stateChangeHeuristics from "./stateChangeHeuristics.js";

const ControlsRow = styled(Row)`
	background: #ffffff;
	margin-bottom: 12px;
	padding: 18px 1.5rem;
`;

const StyledDivider = styled(Divider)`
	margin: 0 12px;
	height: 4.5em;
	top: 0.5em;
	background: #f4f5f6;
`;

const HorizontalDivider = styled(Divider)`
	padding: 0;
	margin: 0;
	background: #f4f5f6;
`;

const ChartCard = styled(DefaultCard)`
	height: 30rem !important;
	padding: 1rem !important;
`;

const ChartRow = styled(Row)`
	padding: 0 1.5rem !important;
	margin: 0 !important;
	margin-bottom: 1rem !important;
`;

const Empty = styled.div`
	text-align: center;
	margin-top: 185px;
`;

const bases = [
	{ shortTitle: "Roteiro Consolidado" },
	{ shortTitle: "Pesquisa Preco" },
	{ shortTitle: "Pesquisa Validade" },
	{ shortTitle: "Ruptura Consolidada" },
	{ shortTitle: "Foto Consolidada" },
	{ shortTitle: "Checklist Consolidado" },
];

const ExploreQueryBuilder = ({ vizState, cubejsApi, setVizState }) => {
	const [base, setBase] = useState(vizState.base ? vizState.base : null);
	const [stack, setStack] = useState(!!vizState?.stack);
	const [showChartValuesLabel, setShowChatValuesLabel] = useState(
		!!vizState?.showChartValuesLabel
	);
	const [percentual, setPercentual] = useState(!!vizState.percentual);
	const [resultSet, setResultSet] = useState(null);
	let members = [];
	let updates = [];

	const resetarMetricas = async (callback = () => {}) => {
		setVizState({});
		await map(members, (member, index) => {
			return map(member, m => {
				return updates[index].remove(m);
			});
		});
		callback();
	};

	useEffect(() => {
		resetarMetricas();
		//FIXME: No futuro, melhorar que esse useEffect funciona
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [base]);

	const callback = ({ resultSet }) => {
		setResultSet(resultSet);
	};

	return (
		<QueryBuilder
			vizState={vizState}
			setVizState={state =>
				setVizState({ ...state, base, stack, percentual, showChartValuesLabel })
			}
			cubejsApi={cubejsApi}
			wrapWithQueryRenderer={false}
			stateChangeHeuristics={(oldState, newState) =>
				stateChangeHeuristics(oldState, newState)
			}
			render={({
				measures,
				availableMeasures,
				updateMeasures,
				dimensions,
				availableDimensions,
				updateDimensions,
				segments,
				updateSegments,
				filters,
				updateFilters,
				timeDimensions,
				availableTimeDimensions,
				updateTimeDimensions,
				isQueryPresent,
				chartType,
				updateChartType,
				validatedQuery,
				cubejsApi,
			}) => {
				members = [measures, dimensions, segments, timeDimensions, filters];
				updates = [
					updateMeasures,
					updateDimensions,
					updateSegments,
					updateTimeDimensions,
					updateFilters,
				];
				const newBases = bases;
				return [
					<ControlsRow type="flex" justify="space-around" align="top" key="1">
						<Col span={24}>
							<Row type="flex" align="top" style={{ paddingBottom: 23 }}>
								<div>
									<MemberGroupTitle title="Relatório Base" />
									{base ? (
										<RemoveButtonGroup
											onRemoveClick={() => {
												resetarMetricas(() => {
													updateChartType(null);
													setBase(null);
												});
											}}>
											<MemberDropdown
												type="selected"
												availableMembers={newBases}
												onClick={updateWith => setBase(updateWith)}>
												{base.shortTitle}
											</MemberDropdown>
										</RemoveButtonGroup>
									) : (
										<MemberDropdown
											type={"new"}
											onClick={updateWith => setBase(updateWith)}
											availableMembers={newBases}>
											{"Relatórios Base"}
											<PlusIcon />
										</MemberDropdown>
									)}
								</div>
								<StyledDivider type="vertical" />
								<MemberGroup
									title="Métricas"
									base={base}
									members={measures}
									availableMembers={
										base
											? availableMeasures.filter(m =>
													m.title.includes(base.shortTitle)
												)
											: []
									}
									addMemberName="Métricas"
									updateMethods={updateMeasures}
								/>
								<StyledDivider type="vertical" />
								<MemberGroup
									title="Dimensões"
									base={base}
									members={dimensions}
									availableMembers={
										base
											? availableDimensions.filter(m =>
													m.title.includes(base.shortTitle)
												)
											: []
									}
									addMemberName="Dimensões"
									updateMethods={updateDimensions}
								/>
								{/* <StyledDivider type="vertical" />
                <MemberGroup
                  title="Segmentos"
                  base={base}
                  members={segments}
                  availableMembers={base ? availableSegments.filter(m => m.title.includes(base.shortTitle)) : []}
                  addMemberName="Segmentos"
                  updateMethods={updateSegments}
                /> */}
								<StyledDivider type="vertical" />
								<TimeGroup
									title="Data"
									base={base}
									members={timeDimensions}
									availableMembers={
										base
											? availableTimeDimensions.filter(m =>
													m.title.includes(base.shortTitle)
												)
											: []
									}
									addMemberName="Data"
									updateMethods={updateTimeDimensions}
								/>
							</Row>
							{!!isQueryPresent && (
								<>
									<HorizontalDivider />
									<Row
										type="flex"
										justify="space-around"
										align="top"
										gutter={24}
										style={{ marginTop: 10 }}>
										<Col span={24}>
											<FilterGroup
												members={filters}
												base={base}
												availableMembers={availableDimensions
													.filter(m => m.title.includes(base.shortTitle))
													.concat(
														availableMeasures.filter(m =>
															m.title.includes(base.shortTitle)
														)
													)}
												addMemberName="Filtro"
												updateMethods={updateFilters}
											/>
										</Col>
									</Row>
								</>
							)}
						</Col>
					</ControlsRow>,
					<ChartRow type="flex" justify="space-around" align="top" gutter={24} key="2">
						<Col span={24} style={{ padding: 0 }}>
							{isQueryPresent ? (
								<>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											marginTop: 8,
											marginBottom: 20,
										}}>
										<SelectChartType
											hidePie={dimensions.length > 1}
											chartType={chartType}
											updateChartType={updateChartType}
											stack={stack}
											percentual={percentual}
											setPercentual={boolean =>
												new Promise(s => {
													setPercentual(boolean);
													return s();
												})
											}
											setStack={boolean =>
												new Promise(s => {
													setStack(boolean);
													return s();
												})
											}
											setShowChatValuesLabel={boolean =>
												new Promise(s => {
													setShowChatValuesLabel(boolean);
													return s();
												})
											}
											showChartValuesLabel={showChartValuesLabel}
										/>
										{base != null && resultSet != null ? (
											<ExportExcelButton
												resultSet={resultSet}
												base={base}
												vizState={vizState}
												stack={stack}
												percentual={percentual}
											/>
										) : (
											<></>
										)}
									</div>
									<ChartCard>
										<ChartRenderer
											setCubeQueryResult={callback}
											vizState={{ query: validatedQuery, chartType }}
											cubejsApi={cubejsApi}
											chartHeight={400}
											base={base}
											stack={stack}
											showChartValuesLabel={showChartValuesLabel}
											percentual={percentual}
										/>
									</ChartCard>
								</>
							) : (
								<Empty>
									<h2>Monte sua pesquisa.</h2>
									<p>Escolha uma métrica ou dimensão para começar.</p>
								</Empty>
							)}
						</Col>
					</ChartRow>,
				];
			}}
		/>
	);
};

ExploreQueryBuilder.propTypes = {
	vizState: PropTypes.object,
	setVizState: PropTypes.func,
	cubejsApi: PropTypes.object,
	chartExtra: PropTypes.array,
};
ExploreQueryBuilder.defaultProps = {
	vizState: {},
	setVizState: null,
	cubejsApi: null,
	chartExtra: null,
};
export default ExploreQueryBuilder;
