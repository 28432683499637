import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @description Renderiza um componente que avisa que não há conteúdo na dashboard e é necessário criar um novo gráfico.
 */
export function DashboardEmpty() {
	return (
		<div
			style={{
				minHeight: "80vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
				padding: "1rem",
				maxWidth: "600px",
				margin: "0 auto",
			}}>
			<h2 style={{ fontSize: "1.3rem" }}>Não há nenhum gráfico ou tabela nesta Dashboard.</h2>
			<Link to="/explore" style={{ width: "100%" }}>
				<Button type="primary" size="large" icon="plus" style={{ width: "100%" }}>
					Adicionar Gráfico
				</Button>
			</Link>
		</div>
	);
}
