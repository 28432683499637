import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { SchemaLink } from "apollo-link-schema";
import { makeExecutableSchema } from "graphql-tools";
import { DashboardService } from "../services/DashboardService";
import { AuthService } from "../services/AuthService";
const cache = new InMemoryCache();

export const getDashboardItems = async () => {
	const { appId, userId } = AuthService.getInstance().getAuthData();

	const jsonDash = await DashboardService.getInstance().getItems(userId, appId);
	let json = jsonDash.data ? jsonDash.data.json_dashboard : "[]";
	let retorno = JSON.parse(json);
	if (typeof retorno == "string") {
		retorno = JSON.parse(retorno);
	}
	return retorno;
};

export const setDashboardItems = async items => {
	try {
		await DashboardService.getInstance().setItems(JSON.stringify(items));
	} catch (err) {
		return err;
	}
};

const nextId = () => {
	const currentId = parseInt(window.localStorage.getItem("dashboardIdCounter"), 10) || 1;
	window.localStorage.setItem("dashboardIdCounter", currentId + 1);
	return currentId.toString();
};

const toApolloItem = i => ({ ...i, __typename: "DashboardItem" });

const typeDefs = `
  type DashboardItem {
    id: String!
    layout: String
    vizState: String
    name: String
  }

  input DashboardItemInput {
    layout: String
    vizState: String
    name: String
  }

  type Query {
    dashboardItems: [DashboardItem]
    dashboardItem(id: String!): DashboardItem
  }

  input UpdateDashboardItemsProps{
    id: String!
    layout: String
    vizState: String
    name: String
  }

  type Mutation {
    createDashboardItem(input: DashboardItemInput): DashboardItem
    updateDashboardItem(id: String!, input: DashboardItemInput): DashboardItem
    deleteDashboardItem(id: String!): DashboardItem
    updateDashboardItems(items: [UpdateDashboardItemsProps]): [DashboardItem]
  }
`;

const schema = makeExecutableSchema({
	typeDefs,
	resolvers: {
		Query: {
			dashboardItems: async () => {
				const dashItems = await getDashboardItems();
				return dashItems.map(toApolloItem);
			},

			dashboardItem: async (_, { id }) => {
				const dashboardItems = await getDashboardItems();
				return toApolloItem(dashboardItems.find(i => i.id.toString() === id));
			},
		},
		Mutation: {
			createDashboardItem: async (_, { input: { ...item } }) => {
				const dashboardItems = await getDashboardItems();
				item = { ...item, id: nextId(), layout: JSON.stringify({ h: 30, w: 50 }) };
				dashboardItems.push(item);
				await setDashboardItems(dashboardItems);
				return toApolloItem(item);
			},
			updateDashboardItem: async (_, { id, input: { ...item } }) => {
				const dashboardItems = await getDashboardItems();

				item = Object.keys(item)
					.filter(k => !!item[k])
					.map(k => ({
						[k]: item[k],
					}))
					.reduce((a, b) => ({ ...a, ...b }), {});

				const index = dashboardItems.findIndex(i => i.id.toString() === id);
				dashboardItems[index] = { ...dashboardItems[index], ...item };
				await setDashboardItems(dashboardItems);
				return toApolloItem(dashboardItems[index]);
			},
			updateDashboardItems: async (_, { items }) => {
				//NOTE: Traz todos os itens do dashboard.
				const dashboardItems = await getDashboardItems();

				//NOTE: Para cada item que foi enviado para atualização, localizamos ele em `dashboardItems` e atualizamos somente o quê foi atualizado.
				items.forEach(item => {
					const index = dashboardItems.findIndex(i => i.id.toString() === item.id);
					dashboardItems[index] = toApolloItem({ ...dashboardItems[index], ...item });
				});

				//NOTE: Setamos o estado mais atual dos itens com o array que acabamos de atualizar.
				await setDashboardItems(dashboardItems);

				//NOTE: Retorna o array atualizado.
				return dashboardItems;
			},
			deleteDashboardItem: async (_, { id }) => {
				const dashboardItems = await getDashboardItems();
				const index = dashboardItems.findIndex(i => i.id.toString() === id);
				const [removedItem] = dashboardItems.splice(index, 1);
				await setDashboardItems(dashboardItems);
				return toApolloItem(removedItem);
			},
		},
	},
});
export default new ApolloClient({
	cache,
	link: new SchemaLink({
		schema,
	}),
});
