import moment from "moment";
import { ISO_DATE_AND_ISO_DATE_TIME } from "../../constant/Regex";

/**
 * @description Função que formata uma string para um formato legível em um gráfico;
 * @param {string} dateToTest Uma data no formato `YYYY-MM-DDTHH:MM:SS.SSS` ou `YYYY-MM-DD`.
 * @returns A string formatada no formato `DD/MM/YYYY`.
 */
export function fDateForChart(dateToTest) {
	if (ISO_DATE_AND_ISO_DATE_TIME.test(dateToTest) && moment(dateToTest).isValid()) {
		return moment(dateToTest).format("DD/MM/YYYY");
	} else {
		return dateToTest;
	}
}
