import { Card } from "antd";
import React from "react";
import styled from "styled-components";

const Container = styled(Card)`
	&.ant-card {
		height: 100%;
		width: 100%;
		overflow: hidden;
		border-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		box-shadow:
			rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
			rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	}

	.ant-card-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 0;
		overflow: hidden;
	}
`;

/**
 * @description Componente de Card com estilização padronizada na aplicação
 */
export function DefaultCard({ children, ...props }) {
	return <Container {...props}>{children}</Container>;
}
