import React from "react";
import { Bar, BarChart } from "recharts";
import { getChartColorByIndex } from "../../../theme/Chart";
import { fDateForChart } from "../../../utils/Chart";
import { fShortenNumber } from "../../../utils/numeral";
import { CartesianChart } from "../CartesianChart";

/**
 * Renderiza um gráfico de barras padrão para uso na aplicação.
 */
export function DefaultBarChart({ resultSet, stack, showChartValuesLabel }) {
	return (
		//TODO: O uso do DrillDown deverá ser revisado e adicionado no futuro. Por enquanto, ele deve ser considerado código morto
		// <DrillDown
		// 	resultSet={resultSet}
		// 	{...restProps}
		// 	Children={({ handleBarClick }) => (
		<CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
			{resultSet.seriesNames().map(({ key, title }, i) => {
				let valor = title.split(",");
				return (
					<Bar
						label={
							showChartValuesLabel
								? {
										position: "top",
										fontSize: 10,
										formatter: fShortenNumber,
									}
								: undefined
						}
						key={key}
						stackId={stack ? "a" : null}
						dataKey={key}
						name={fDateForChart(valor[0])}
						fill={getChartColorByIndex(i)}
						// onClick={event => handleBarClick(event, yValues)}
					/>
				);
			})}
		</CartesianChart>
		// 	)}
		// />
	);
}
