import { useMutation } from "@apollo/react-hooks";
import { Dropdown, Icon, Menu, Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { DELETE_DASHBOARD_ITEM } from "../../graphql/mutations";
import { GET_DASHBOARD_ITEMS } from "../../graphql/queries";

/**
 * Renderiza um menu dropdown para uso nos itens da dashboard
 */
export function DashboardItemDropdown({ itemId }) {
	const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
		refetchQueries: [
			{
				query: GET_DASHBOARD_ITEMS,
			},
		],
	});

	const dashboardItemDropdownMenu = (
		<Menu>
			<Menu.Item>
				<Link to={`/explore?itemId=${itemId}`}>Editar</Link>
			</Menu.Item>
			<Menu.Item
				onClick={() =>
					Modal.confirm({
						title: "Têm certeza que deseja deletar este cubo?",
						okText: "Sim",
						okType: "danger",
						cancelText: "Não",

						onOk() {
							removeDashboardItem({
								variables: {
									id: itemId,
								},
							});
						},
					})
				}>
				Remover
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown overlay={dashboardItemDropdownMenu} placement="bottomLeft" trigger={["click"]}>
			<Icon type="menu" />
		</Dropdown>
	);
}
