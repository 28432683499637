import axios from "axios";
import { host } from "../../constant";
import { AuthService } from "../AuthService";

/**
 * @description Serviço para tratar das dashboards e seus gráficos no backend.
 */
export class DashboardService {
	/**
	 * @type {DashboardService}
	 */
	static instance;

	static getInstance() {
		if (!DashboardService.instance) {
			DashboardService.instance = new DashboardService();
		}
		return DashboardService.instance;
	}

	async getItems() {
		try {
			const { appId, userId } = AuthService.getInstance().getAuthData();

			return await axios.post(`${host}/biback/dashboard/search`, {
				id_usuario: userId,
				appId: appId,
			});
		} catch (err) {
			return err; //FIXME: Melhorar tratamento de erros. Para isso, necessário padronizar erros no backend
		}
	}

	async setItems(json_dashboard) {
		try {
			const { appId, userId } = AuthService.getInstance().getAuthData();
			return await axios.post(`${host}/biback/dashboard/create`, {
				json_dashboard,
				id_usuario: userId,
				appId: appId,
			});
		} catch (err) {
			return err; //FIXME: Melhorar tratamento de erros. Para isso, necessário padronizar erros no backend
		}
	}
}
