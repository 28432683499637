import { CubeProvider } from "@cubejs-client/react";
import React, { useMemo } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { generateCubeInstance } from "../../services/CubeJsService";

export function CubeJsProvider({ children }) {
	const { isAuthenticated } = useAuthContext();

	const cubeApiInstance = useMemo(() => {
		if (!isAuthenticated) {
			return null;
		}
		return generateCubeInstance();
	}, [isAuthenticated]);

	if (!cubeApiInstance) return null;

	return <CubeProvider cubejsApi={cubeApiInstance}>{children}</CubeProvider>;
}
