import styled, { css } from "styled-components";

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	margin: 0.5rem 1rem;

	${({ withMargin }) =>
		withMargin &&
		css`
			margin: 0.5rem 1rem;
		`}

	${({ isNumberChart }) =>
		isNumberChart &&
		css`
			margin: 0;
			top: 1rem;
			right: 1rem;
			left: 1rem;
			position: absolute;
			z-index: 10;
			opacity: 0;

			transition: opacity 0.3s;

			h3 {
				font-size: 1rem;
				color: #a1a1b5;

				border-radius: 0.5rem;
				padding: 0 0.2rem;

				background: rgba(255, 255, 255, 0.29);
				backdrop-filter: blur(14.4px);
				-webkit-backdrop-filter: blur(14.4px);
				border: 1px solid rgba(255, 255, 255, 0.3);
			}

			&:hover,
			&:active {
				opacity: 1;
			}
		`}
`;

export const Content = styled.div`
	flex: 1;
	margin: 1rem 1.5rem;
	overflow: hidden;

	${({ noPadding }) =>
		noPadding &&
		css`
			margin: 0;
		`};
`;

export const SwitchWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	margin: 0.3rem 0;
`;

export const ChartTitle = styled.h3`
	margin: 0;
`;
