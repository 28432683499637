import { sumBy } from "lodash";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { getChartColorByIndex } from "../../../theme/Chart";
import { fDateForChart } from "../../../utils/Chart";
import { fShortenNumber, fShortenPercent } from "../../../utils/numeral";

/**
 * Renderiza um gráfico de pizza/torta padrão para uso na aplicação.
 */
export function DefaultPieChart({ resultSet, percentual, showChartValuesLabel }) {
	const firstField = resultSet.seriesNames()[0];
	const firstFieldName = firstField?.key || "NOT_FOUND";

	let total = sumBy(resultSet.chartPivot(), firstFieldName);
	let dados = resultSet
		.chartPivot()
		.map(valor => {
			let novo = valor;
			if (percentual) {
				novo[firstFieldName] = (novo[firstFieldName] / total) * 100;
			}
			novo.category = fDateForChart(novo.category);
			novo.x = fDateForChart(novo.x);
			return novo;
		})
		.filter(row => row[firstFieldName] > 0);

	return (
		<AutoSizer>
			{({ width, height }) => (
				<PieChart width={width} height={height}>
					<Pie
						label={
							showChartValuesLabel
								? ({ value }) => {
										return percentual
											? fShortenPercent(value)
											: fShortenNumber(value);
									}
								: undefined
						}
						isAnimationActive={false}
						data={dados}
						nameKey="x"
						dataKey={firstFieldName}
						fill="#8884d8">
						{resultSet.chartPivot().map((_, i) => (
							<Cell key={i} fill={getChartColorByIndex(i)} />
						))}
					</Pie>
					<Legend />
					<Tooltip
						formatter={value =>
							percentual ? fShortenPercent(value) : fShortenNumber(value)
						}
					/>
				</PieChart>
			)}
		</AutoSizer>
	);
}
