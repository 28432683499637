import { Table } from "antd";
import { find } from "lodash";
import React, { useMemo } from "react";
import { fDateForChart } from "../../../utils/Chart";
import SimpleBar from "simplebar-react";

/**
 * Renderiza um gráfico com tabela padronizado para uso na aplicação.
 */
export function DefaultTableChart({ resultSet, base }) {
	const dataSource = useMemo(
		() => resultSet.tablePivot().map((row, index) => ({ ...row, key: index })),
		[resultSet]
	);

	return (
		<SimpleBar style={{ maxHeight: "100%" }}>
			<Table
				pagination={false}
				columns={resultSet.tableColumns().map(c => {
					let title = c.title
						.replace(base.shortTitle + " Bi", "")
						.replace(base.shortTitle, "");
					return {
						title,
						dataIndex: c.key,
						key: c.key,
						render: (text, record) => {
							if (find(record, (r, key) => r === text && key.includes("data"))) {
								return fDateForChart(text);
							} else {
								if (c.format === "currency")
									return parseFloat(text).toLocaleString("BRL", {
										style: "currency",
										currency: "BRL",
									});
								return text;
							}
						},
					};
				})}
				dataSource={dataSource}
			/>
		</SimpleBar>
	);
}
